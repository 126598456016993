import axios from 'axios'
import {authHeader} from '../../middleware/auth-header'
import API_URL from '../../constants'

export const state = {
  workflowLoading: true,
  workflow: {},
}

export const mutations = {
  //cannot do async in mutations.
  populateWorkflow(state, workflow){
    state.workflow = workflow
  },
  loaded(state){
    state.workflowLoading = false
  },
  load(state){
    state.workflowLoading = true;
  },
  selectQueueMutation(state, queue){
    state.workflow.queue = queue
  }
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async loadWorkflow({commit, dispatch}, workflowDetails){
    commit("load")
    try {
      const workflowId = workflowDetails.workflowId
      const projectName = workflowDetails.projectName
      const res = await axios.get(`${API_URL}/workflows/${projectName}/${workflowId}`, await authHeader())
      commit('populateWorkflow', res.data)
      commit('loaded')
    } catch (e) {
      let data = JSON.parse(e.response.data)
      dispatch('notify', {message:data.message, status:false})
      commit('loaded')
    }    
  },
  selectWorkflowQueue({commit}, queue){
    commit('selectQueueMutation', queue);
  },
}

export const getters = {
  getWorkflow: state => state.workflow,
  getWorkflowStatus: state => state.workflow.status,
  getWorkflowForStepUpdate: state => () => state.workflow,
  workflowLoading: state => state.workflowLoading,
}