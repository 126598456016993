import axios from 'axios'
import {portalAuthHeader} from '../../middleware/portal-header'
import {authHeader} from '../../middleware/auth-header'
import API_URL from '../../constants';

// TODO: parse local, dev, test and prod environments. 
const PORTAL_LOCAL_API_URL = `http://gsi-staging.surfaceintelligence.test/gcc/v1`
const PORTAL_DEV_API_URL = `https://gsi-staging.dev.surfaceintelligence.com/gcc/v1`
const LIVE_PORTAL_API_URL = `https://gsi-staging.surfaceintelligence.com/gcc/v1`


const authService = axios.create({
  baseURL: PORTAL_DEV_API_URL,
  withCredentials: true,
  xsrfCookieName: 'csrf_access_token'
});


export const state = {
  project: null,
  layerTypes: undefined,
  projectWorkflows: undefined,
  projectWorkflowsLoading: false,
  steps: undefined,
  projectStepsLoading: false,
  jobs: undefined,
  projectJobsLoading: false,
  files: undefined,
  projectFilesLoading: false,
  urls: undefined,
  projectURLsLoading: false
}

export const mutations = {
  populatePortalDetails(state, portalProjectDetails){
    state.project = portalProjectDetails
  },
  clearPortalDetails(state){
    state.project = null
  },
  populateLayerTypes(state, layerTypes){
    state.layerTypes = layerTypes
  },
  populateProjectWorkflows(state, workflows){
    state.projectWorkflows = workflows
  },
  populateProjectWorkflowSteps(state, steps){
    state.steps = steps
  },
  populateProjectJobs(state, jobs){
    state.jobs = jobs
  },
  populateProjectFiles(state, files){
    state.files = files
  },
  populateProjectURLs(state, urls){
    state.urls = urls
  },
  setProjectWorkflowsLoading(state){
    state.projectWorkflowsLoading = true
  },
  setProjectWorkflowsLoaded(state){
    state.projectWorkflowsLoading = false
  },
  setProjectStepsLoading(state){
    state.projectStepsLoading = true
  },
  setProjectStepsLoaded(state){
    state.projectStepsLoading = false
  },
  setProjectJobsLoading(state){
    state.projectJobsLoading = true
  },
  setProjectJobsLoaded(state){
    state.projectJobsLoading = false
  },
  setProjectFilesLoading(state){
    state.projectFilesLoading = true
  },
  setProjectFilesLoaded(state){
    state.projectFilesLoading = false
  },
  setProjectURLsLoading(state){
    state.projectURLsLoading = true
  },
  setProjectURLsLoaded(state){
    state.projectURLsLoading = false
  }
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async loadPortalProjectData({commit, dispatch}, project){
    try {
      const res = await authService.get(`/project/${project}`, await portalAuthHeader())
      commit('populatePortalDetails', res.data)
    } catch (e) {
      dispatch('notify', {message:"Portal Load Failed", status:false})
    }    
  },
  wipePortalDetails({commit}) {
    commit('clearPortalDetails')
  }, 
  async updatePortalProject({commit, dispatch}, portalDetails){
    try{
      const res = await authService.post('/', portalDetails, await portalAuthHeader())
      commit('populatePortalDetails', res.data)
    } catch (e) {
      dispatch('notify', {message:"Portal Update Failed: " + e.message, status:false})
    }
  },
  async addPortalProject({commit, dispatch}, portalDetails){
    try{
      let bodyData = new FormData();
      bodyData.append('name', portalDetails.name)
      bodyData.append('uri', portalDetails.uri)
      bodyData.append('product_name', portalDetails.product)
      bodyData.append('user_name', portalDetails.username)
      bodyData.append('password', portalDetails.password)
      bodyData.append('is_public', false)

      const res = await authService.post('/add_project/', bodyData, await portalAuthHeader())
      commit('populatePortalDetails', res.data)
    } catch (e) {
      dispatch('notify', {message:"Portal Update Failed: " + e.message, status:false})
    }
  },
  async addLayer({commit, dispatch}, portalDetails){
    try{
      let bodyData = new FormData();
      bodyData.append('project_name', portalDetails.name)
      bodyData.append('name', portalDetails.layerName)
      bodyData.append('type', portalDetails.layerType)
      bodyData.append('user_name', portalDetails.username)
      bodyData.append('password', portalDetails.password)

      const res = await authService.post(`/add_layer/`, bodyData, await portalAuthHeader())
      commit('populatePortalDetails', res.data)
    } catch (e) {
      dispatch('notify', {message:"Portal Update Failed: " + e.message, status:false})
    }
  },
  async loadLayerTypes({commit,dispatch}){
    try{
      const res = await authService.get(`/layer_types/`, await portalAuthHeader())
      commit('populateLayerTypes', res.data.layer_types)
    }
    catch(e){
      dispatch('notify', {message:"Failure getting Layer Types" + e.message, status:false})
    }
  },
  async loadWorkflowsForPortalProject({commit, dispatch},project){
    commit('setProjectWorkflowsLoading')
    try{
      let res = await axios.get(`${API_URL}/workflows/${project}`, await authHeader())
      commit('populateProjectWorkflows', res.data)
    }
    catch(e){
      dispatch('notify', {message:"Failure getting Workflows for Portal Project: " + e.message, status:false})
    }
    commit('setProjectWorkflowsLoaded')
  },
  async loadWorkflowStepsForPortalProject({commit, dispatch, state}, workflowId){
    commit('setProjectStepsLoading')
    commit('setProjectJobsLoading')
    commit('setProjectFilesLoading')
    commit('setProjectURLsLoading')
    commit('populateProjectWorkflowSteps', undefined)
    commit('populateProjectJobs', undefined)
    commit('populateProjectFiles', undefined)
    commit('populateProjectURLs', undefined)

    try {
      const projectName = state.project.name
      const res = await axios.get(`${API_URL}/steps/${projectName}/${workflowId}`, await authHeader())
      commit('populateProjectWorkflowSteps', res.data)
    } catch (e) {
      dispatch('notify', {message:"Failure getting Steps for Portal Project: " + e.message, status:false})
    }    
    commit('setProjectStepsLoaded')
    commit('setProjectJobsLoaded')
    commit('setProjectFilesLoaded')
    commit('setProjectURLsLoaded')
  },
  async loadJobsForPortalProject({commit, dispatch, state}, wsDetails){
    commit('setProjectJobsLoading')
    commit('setProjectFilesLoading')
    commit('setProjectURLsLoading')
    commit('populateProjectFiles', undefined)
    commit('populateProjectURLs', undefined)
    try {
      let step = wsDetails.workflowStep
      let workflowId = wsDetails.workflowId
      const projectName = state.project.name
      const res = await axios.get(`${API_URL}/jobs_status_by_step/${projectName}/${workflowId}/${step}`, await authHeader())
      commit('populateProjectJobs', res.data)
    } catch (e) {
      dispatch('notify', {message:"Failure getting Jobs for Portal Project: " + e.message, status:false})
    }    
    commit('setProjectJobsLoaded')
    commit('setProjectFilesLoaded')
    commit('setProjectURLsLoaded')
  },
  async loadFilesForPortalProject({commit, dispatch, state}, wsjDetails){
    commit('setProjectFilesLoading')
    commit('setProjectURLsLoading')
    commit('populateProjectURLs', undefined)

    try {
      let projectName = state.project.name
      let workflowId = wsjDetails.workflowId
      let step = wsjDetails.step
      let jobId = wsjDetails.jobId
      
      const res = await axios.get(`${API_URL}/job_files/${projectName}/${workflowId}/${step}/${jobId}?get_status`, await authHeader())
      commit('populateProjectFiles', res.data.outputs.filter(file => file.urls.some(url => url.exists)))
    } catch (e) {
      dispatch('notify', {message:"Failure getting Jobs for Portal Project: " + e.message, status:false})
    }    
    commit('setProjectURLsLoaded')
    commit('setProjectFilesLoaded')
  },
  async clearWorkflowStepData({commit}){
    commit('populateProjectWorkflowSteps', undefined)
    commit('populateProjectJobs', undefined)
    commit('populateProjectFiles', undefined)
    commit('populateProjectURLs', undefined)
  },
  async uploadFilesToLayer({commit, dispatch, state}, fileSendModel){
    try{
      let bodyData = new FormData();
      bodyData.append('project_name', fileSendModel.project)
      bodyData.append('layer', fileSendModel.layer)
      bodyData.append('files', fileSendModel.files)
      bodyData.append('user_name', fileSendModel.username)
      bodyData.append('password', fileSendModel.password)
      for(let i=0; i<fileSendModel.files.length; i++){
        bodyData.append('file', fileSendModel.files[i].url)  
        bodyData.append('band', 1)
      }

      const res = await authService.post(`/add_files/`, bodyData, await portalAuthHeader())
    }
    catch(error){
      console.log('error in posting files: ', error.message)
    }
  },
  loadURLsForPortalProject({commit, dispatch, state}, dataset){
    commit('setProjectURLsLoading')
    try {
      let urls = state.files.find(file => file.dataset===dataset)
      commit('populateProjectURLs', urls.urls)
    } catch (e) {
      dispatch('notify', {message:"Failure getting URls for Portal Project: " + e.message, status:false})
    }    
    commit('setProjectURLsLoaded')  
  }
}

export const getters = {
  getProject: state => state.project,
  getLayer: state => state.layer,
  getMessage: state => state.message,
  getlayerTypes: state => state.layerTypes,
  getProjectWorkflows: state => state.projectWorkflows,
  getProjectWorkflowSteps: state => state.steps,
  getJobsForPortalProject: state => state.jobs,
  getFilesForPortalProject: state => state.files,
  getURLSforPortalProject: state => state.urls,
  getProjectStepsLoading: state => state.projectStepsLoading,
  getProjectWorkflowsLoading: state => state.projectWorkflowsLoading,
  getProjectJobsLoading: state => state.projectJobsLoading,
  getProjectFilesLoading: state => state.projectFilesLoading,
  getProjectURLsLoading: state => state.projectURLsLoading
}