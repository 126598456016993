import axios from 'axios'
import {authHeader} from '../../middleware/auth-header'
import store from '../../store'
import API_URL from '../../constants';


function initialState(){
  return {
    loading: false,
    chosenProject: null,
    projects: null,
    projectData: null,
    projectDataLoading: true
  }
}

export const state = initialState

export const mutations = {
  //cannot do async in mutations.
  loadProjects(state, projects){
    state.projects = projects
  },
  loadProjectMetadata(state, projectData){
    state.projectData = projectData
  },
  resetProjects(state){
    const s = initialState()
    Object.keys(s).forEach(key => {state[key] = s[key]})
  },
  setProjectDataLoading(state, isLoading){
    state.projectDataLoading = isLoading
  },
  selectProject(state, project){
    state.chosenProject = project
  }
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async loadProjects({commit, dispatch, state}){
    commit("load")
    try {
      let projects = []
      let projectVuex = JSON.parse(JSON.stringify(state.projects))
      if(projectVuex !== null){
        projects = state.projects
      }else{
        const res = await axios.get(API_URL + '/projects', await authHeader())
        res.data.forEach(p => projects.push({name: p, active:true})) 
      }
      commit('loadProjects', projects)
    } catch (e) {
      dispatch('notify', {message:"Error Loading Projects: " + e.message, status:false})
    }    
  },
  filterProject({commit, state}, project){
    try{
      const projects = state.projects
      projects.forEach(p => p.active = p.name === project)
      commit('loadProjects', projects)
    }
    catch(e){
      console.log(e)
    }
  },
  async loadProjectMetadata({commit, dispatch, state}, project){
    try{
      let workflows = JSON.parse(JSON.stringify(store.getters.getWorkflows()))
      let projectWorkflows = workflows !== undefined? workflows.filter(p => p.project === project.name) : []
      if(projectWorkflows.length === 0){
        let res = await axios.get(`${API_URL}/workflows/${project.name}`, await authHeader())
        projectWorkflows = res.data
      }
      let dates = projectWorkflows.map(wf => wf.last_update === "" ? wf.created:wf.last_update)
      let maxDate = new Date(Math.max(...dates.map(e=> (e))))

      let activeProjects = [{
        "name": project.name, 
        "total": projectWorkflows.length,
        "active": projectWorkflows.filter(p => p.status === "active").length,
        "paused": projectWorkflows.filter(p => p.status === "paused").length,
        "pending": projectWorkflows.filter(p => p.status === "pending").length,
        "abandoned": projectWorkflows.filter(p => p.status === "abandoned").length,        
        "maxDate": maxDate
      }]
      if(project.active){
        if(state.projectData !== null){
          state.projectData.forEach(p => p.name !== project.name ? activeProjects.push(p) : null)
        }
      }
      else{
        activeProjects = state.projectData.filter(p => p.name !== project.name)
      }

      commit('loadProjectMetadata', activeProjects)
      commit('setProjectDataLoading',false)

    }catch(e){
      dispatch('notify', {message:"Error Loading Project Data: " + e.message, status:false})
    }
  },
  selectProject({commit, dispatch}, project){
    commit('selectProject', project)
    dispatch('filterQueues', project)
  },
  resetAllProjects({commit}){
    commit('resetProjects')
  }
}

export const getters = {
  projectDataLoading: state => state.projectDataLoading,
  getProjects: state => state.projects,
  getProjectsForWorkflows: state => () => state.projects,
  getProjectData: state => state.projectData,
  getChosenProject: state => state.chosenProject
}