import IdleVue from "idle-vue";
import Vue from 'vue';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faPlus, faMinus, faCheck, faArrowCircleDown, faArrowCircleUp, faTrash, faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


library.add(faSpinner, faPlus, faMinus, faCheck, faArrowCircleDown, faArrowCircleUp, faTrash, faCog)

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(IdleVue, {store, idleTime:3500000, startAtIdle: false}) //start at 3500000 seconds
  .use(store)
  .use(router)
  .mount('#app');
