import axios from 'axios'
import {authHeader} from '../../middleware/auth-header'
import store from '../../store'
import likeSearch from '../../utils/likeSearch'
import router from '../../router'
import API_URL from '../../constants'

export const state = {
  loading: true,
  allWorkflows: [{}],
  filteredWorkflows: [{}],
  selectedStatus: "",
  selectedProject: "",
  projectNames: [],
  statuses: [{}],
  workflowSearch: ""
}

export const mutations = {
  //cannot do async in mutations.
  populateWorkflows(state, workflows){
    state.allWorkflows = workflows
    state.filteredWorkflows = workflows
  },
  populateProjects(state, projects){
    state.projectNames = projects
  },
  populateStatuses(state, statuses){
    state.statuses = statuses
  },
  populateWorkflowSearch(state,searchTerm){
    state.workflowSearch = searchTerm
  },
  loaded(state){
    state.loading = false
  },
  load(state){
    state.loading = true;
  },
  selectProject(state, selectedProject){
    state.selectedProject = selectedProject
  },
  selectStatus(state, selectedStatus){
    state.selectedStatus = selectedStatus
  },
  filterWorkflows(state, workflows){
    state.filteredWorkflows = workflows
  }
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async getAllWorkflows({commit, state, dispatch},project=""){
    commit("load")
    try {    
      let workflows = [] 
      if(project === ""){
        // clicked on Workflows link at nav so show all projects.
        let projectData = JSON.parse(JSON.stringify(store.getters.getProjectsForWorkflows()))
        if(projectData === null){
          await dispatch('loadProjects')
          projectData = JSON.parse(JSON.stringify(store.getters.getProjectsForWorkflows()))
        }
        let projects = projectData.filter(p => p.active).map(p => p.name)
        for(var i = 0; i < projects.length; i++){
          let res = await axios.get(`${API_URL}/workflows/${projects[i]}`, await authHeader())
          if(res.data.length > 0){
            for(var j = 0; j < res.data.length; j++){
              if(state.searchTerm!==""){
                let likeExpr = RegExp.escape(state.workflowSearch)
                let match = new RegExp(likeExpr.replace("%", ".*").replace("_", ".")).exec(res.data[j].name) != null;
                if(match){
                  workflows.push(res.data[j])
                }
              }
              else{
                workflows.push(res.data[j])
              }
            }
          }
        }
      }
      else{
        router.push({path: '/Workflows'})
        dispatch('filterProject',project)
        let res = await axios.get(`${API_URL}/workflows/${project}`, await authHeader())
        res.data.forEach(d => workflows.push(d))
      }

      workflows.sort((a,b) => {return b.created - a.created})
      commit('populateWorkflows', workflows)
      let statusNames = workflows.map(wf => wf.status).filter((value, index, self) => self.indexOf(value) === index)

      let activeStatuses = state.statuses.filter(as => as.active).map(as => as.name)
      let statuses = statusNames.map(wf => ({active: activeStatuses.includes(wf), name: wf}))


      dispatch('filterWorkflowStatus')
      commit('populateProjects', workflows.map(wf => wf.project).filter((value, index, self) => self.indexOf(value) === index))
      commit('populateStatuses', statuses)
      commit('loaded')
    } catch (e) {
      dispatch('notify', {message:e.message, status:false})
    }    
  },
  filterWorkflowProject({commit, state}, project){
    commit("load")
    let filteredWorkflows = state.allWorkflows.filter(wf => wf.project == project)
    commit('filterWorkflows', filteredWorkflows)     
    commit('selectProject', project)   
    commit('selectStatus', "") //reset the status filter 
    commit('loaded')
  },
  filterWorkflowStatus({commit, state}){
    commit("load")
    let activeStatuses = state.statuses.filter(s => s.active).map(s => s.name)
    let filteredWorkflows = state.allWorkflows.filter(wf => activeStatuses.includes(wf.status))
    commit('filterWorkflows', filteredWorkflows) 
    //commit('selectStatus', status)    
    commit('loaded')
  },
  clearWorkflowSearch({commit}){
    commit('populateWorkflowSearch',"")
  },
  searchWorkflows({commit}, searchTerm){
    commit('populateWorkflowSearch', searchTerm)
  }
}

export const getters = {
  getWorkflows: state => () => state.allWorkflows,
  getFilteredWorkflows: state => state.filteredWorkflows,
  isLoading: state => state.loading,
  getSelectedProject: state => state.selectedProject,
  getSelectedStatus: state => state.selectedStatus,
  getStatuses: state => state.statuses
}