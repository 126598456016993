import axios from 'axios'
import {authHeader} from '../../middleware/auth-header'
import API_URL from '../../constants';

export const state = {
  stepConfig: [],
  stepOverrides: [],
  stepConfigLoading: true,
  stepConfigDetails: {},
  addingNewStepOverride: false,
  originalVersion: "",
  originalSource: ""
}

export const mutations = {
  stepConfig(state, config){
    state.stepConfig = config
  },
  setStepConfigDetails(state, details){
    state.stepConfigDetails = details
  },
  stepConfigLoad(state){
    state.stepConfigLoading = true
  },
  stepConfigLoaded(state){
    state.stepConfigLoading = false
  },
  addingNewStepOverride(state){
    state.addingNewStepOverride = true
  },
  newStepOverrideAdded(state){
    state.addingNewStepOverride = false
  },
  updateStepConfigOverrides(state, overrides){
    state.stepOverrides = overrides
  },
  setOriginalVersion(state, version){
    state.originalVersion = version
  },
  setOriginalSource(state, source){
    state.originalSource = source
  },
  clearStepOverrides(state){
    state.stepOverrides = []
  }
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async loadStepConfig({commit, dispatch}, stepDetails){
    commit("load")
    try {
      commit("stepConfigLoad")
      commit("clearStepOverrides")
      const project = stepDetails.project;
      const workflow_id = stepDetails.workflow_id;
      const step = stepDetails.step;
      commit("setStepConfigDetails", {project, workflow_id, step})
      const res = await axios.get(`${API_URL}/step_config/${project}/${workflow_id}/${step}`, await authHeader())      
      let sortedData = {"definition":res.data.definition, "variables": []}
      res.data.variables.forEach(configVar => sortedData.variables.push({"order": 
        configVar.source==="step_override"? 1:
        configVar.source==="optional"? 2: 
        configVar.source==="step_var"? 3:
        configVar.source==="static"? 4:
        configVar.source==="auto"? 5:
        configVar.source==="step_input"? 6:
        configVar.source==="step_output"? 7:
        999, 
        ...configVar
      }))
      sortedData.variables.sort((a,b) => a.order - b.order)

      commit('stepConfig', sortedData)
      commit("updateStepConfigOverrides", res.data.variables.filter(jcv => jcv.source==="step_override"))
      commit("setOriginalVersion", res.data.definition.version)
      commit("setOriginalSource", res.data.definition.source)
      commit('stepConfigLoaded')
    } catch (e) {
      dispatch('notify', {message:"Error Loading Step Config: " + e.message, status:false})
    }   
  },
  async updateStepConfig({commit, state}, variablesToOverride){
    commit("stepConfigLoad")
    let stepOverrides = state.stepOverrides
    if(stepOverrides.length>0){
      let stepOverrideExists = stepOverrides.find(jo => jo!==null && jo.name === variablesToOverride.name)
      if(stepOverrideExists){
        stepOverrides = stepOverrides.splice(jo => jo.name === variablesToOverride.name)
      }
    }
    stepOverrides.push({"name": variablesToOverride.name, "value": variablesToOverride.value})
    commit("updateStepConfigOverrides", stepOverrides)
    commit('stepConfigLoaded')
  },
  async saveStepConfig({commit, state, dispatch}){
    commit("stepConfigLoad")
    try{
      let stateConfig = state.stepConfig
      const project = state.stepConfigDetails.project;
      const workflow_id = state.stepConfigDetails.workflow_id;
      const step = state.stepConfigDetails.step;  
      let stepOverrideVariables = state.stepOverrides
      let stepOverrides = []
      if(stateConfig.definition.source==="step_override"){
        stepOverrides = {"version": stateConfig.definition.version, "variables": stepOverrideVariables}
      }
      else{
        stepOverrides = {"variables": stepOverrideVariables}
      }
  
      let body = {project, workflow_id, step, "overrides": stepOverrides}
      await axios.post(`${API_URL}/modify_step`, body, await authHeader())
      dispatch('notify', {message:"Step Config Details Updated ", status:true})
      dispatch('loadStepConfig', {project,workflow_id,step})
    }
    catch(e){
      console.log(e)
    }
    commit('stepConfigLoaded')
  },
  async resetAllOVerrides({commit, state, dispatch}){
    commit("stepConfigLoad")
    try{
      const project = state.stepConfigDetails.project;
      const workflow_id = state.stepConfigDetails.workflow_id;
      const step = state.stepConfigDetails.step;  
      let body = {project, workflow_id, step, "overrides": {}}
      await axios.post(`${API_URL}/modify_step`, body, await authHeader())
      await dispatch('loadStepConfig', {project,workflow_id,step})
      commit("clearStepOverrides")
      dispatch('notify', {message:"Step Config Overrides Cleared", status:true})
    }catch(e){
      dispatch('notify', {message:"Failed to Reset Step Overrides", status:false})
    }
    commit("stepConfigLoaded")
  },
  async updateVersion({commit, state, dispatch}){
    let stateVersion = state.originalVersion
    let stateConfig = state.stepConfig
    if(stateVersion!==stateConfig.definition.version){
      stateConfig.definition.source = "step_override"
    }
    else{
      stateConfig.definition.source = state.originalSource
    }
    commit('stepConfig', stateConfig)
    dispatch('notify', {message:"Step Config Version updated, please save to finalise ", status:true})
  },
  async resetVersionOverride({commit, state, dispatch}){
    commit("stepConfigLoad")
    try{
      let stateConfig = state.stepConfig
      const project = state.stepConfigDetails.project;
      const workflow_id = state.stepConfigDetails.workflow_id;
      const step = state.stepConfigDetails.step;  
      let stepOverrideVariables = state.stepOverrides

      let overridesAlreadyFeatured = stateConfig.variables
        .filter(jo => stepOverrideVariables
          .map(jov => jov.name)
          .includes(jo.name))

      if(overridesAlreadyFeatured.length>0){
        overridesAlreadyFeatured.forEach(so => {if(so.name!==undefined && so.value!==undefined){
          stepOverrideVariables.push({"name": so.name, "value":so.value})
        }})        
      }

      let stepOverrides = {"variables": stepOverrideVariables}
  
      let body = {project, workflow_id, step, "overrides": stepOverrides}
      await axios.post(`${API_URL}/modify_step`, body, await authHeader())
      dispatch('loadStepConfig', {project,workflow_id,step})
      dispatch('notify', {message:"Step Config Version Reset", status:true})
    }catch(e){
      dispatch('notify', {message:"Failed to Reset Step Version", status:false})
    }
    commit("stepConfigLoaded")
  },
  addStepConfigOverride({commit, state, dispatch}, newStepOverride){
    commit("addingNewStepOverride")
    let stepOverrides = state.stepOverrides
    if(stepOverrides.length>0){
      let stepOverrideExists = stepOverrides.find(jo => jo!==null && jo.name === newStepOverride.name)
      if(stepOverrideExists){
        dispatch('notify', {message: "this variable already exists as an override", status: false})
      }
      else{
        stepOverrides.push({"name": newStepOverride.name, "value": newStepOverride.value})
      }
    }
    else{
      stepOverrides.push({"name": newStepOverride.name, "value": newStepOverride.value})
    }

    commit("updateStepConfigOverrides", stepOverrides)

    let stepConfig = state.stepConfig
    let stepConfigExists = stepConfig.variables.find(jcv => jcv!==null && jcv.name === newStepOverride.name)
    if(stepConfigExists){
      dispatch('notify', {message: "this variable already exists in the step config", status: false})
    }
    else{
      stepConfig.variables.push({"name": newStepOverride.name, "source": "step_override", "type": "str", "value": newStepOverride.value})
    }
    commit('stepConfig', stepConfig)
    commit('newStepOverrideAdded')    
    dispatch('notify', {message:"Step Config Override Added, please save to finalise ", status:true})
  },
  removeStepOverride({state, dispatch, commit}, overrideToRemove){
    let stepOverrides = state.stepOverrides
    if(stepOverrides.length>0){
      let stepOverrideExists = stepOverrides.find(jo => jo!==null && jo.name === overrideToRemove.name)
      if(stepOverrideExists){
        stepOverrides = stepOverrides.filter(jo => jo!==null && jo.name !== overrideToRemove.name)
        commit("updateStepConfigOverrides", stepOverrides)
      }
    }

    let stepConfig = state.stepConfig
    let stepConfigExists = stepConfig.variables.find(jcv => jcv!==null && jcv.name === overrideToRemove.name)
    if(stepConfigExists){
      stepConfig.variables = stepConfig.variables.filter(jcv => jcv!==null && jcv.name !== overrideToRemove.name)
    }
    commit('stepConfig', stepConfig)
    dispatch('notify', {message:"Step Config Override removed, please save to finalise", status:true})
  }
}

export const getters = {
  getStepConfig: state => state.stepConfig,
  stepConfigLoading: state => state.stepConfigLoading,
  getStepOverrides: state => state.stepOverrides,
  getAddingNewStepOverride: state => state.addingNewStepOverride
}