import axios from 'axios'
import router from '../../router'
import {authHeader} from '../../middleware/auth-header'
import { Auth } from 'aws-amplify'
import API_URL from '../../constants'
import store from '../../store'

const constants = {
  workflowDictionary: {
    'pending': ['activate', 'abandon', 'initialise', 'delete'],
    'active': ['pause', 'complete', 'abandon', 'initialise'], 
    'paused': ['activate', 'complete', 'abandon', 'initialise'],
    'error': ['delete']
  },
  superCommandDictionary: [
    {
      'workflowStatus': 'pending', 
      'jobCommandDict': [
        {'jobStatuses': ['UNKNOWN', 'RUNNABLE', 'PENDING'], 'commands': ['hold', 'status']}
      ]
    },
    {
      'workflowStatus': 'active', 
      'jobCommandDict': [
        {"jobStatuses": ['UNKNOWN'], "commands": ['hold', 'status']}, 
        {"jobStatuses": ['PENDING'], "commands": ['hold', 'force_run', 'status']}, 
        {"jobStatuses": ['RUNNABLE'], "commands": ['run', 'hold', 'status']}, 
        {"jobStatuses": ['SUBMITTED'], "commands": ['cancel', 'terminate', 'reset', 'status']}, 
        {"jobStatuses": ['RUNNING'], "commands": ['terminate', 'reset', 'status']}, 
        {"jobStatuses": ['COMPLETED'], "commands": ['remove', 'reset']},
        {"jobStatuses": ['FAILED', 'INCOMPLETE'], "commands": ['retry', 'remove', 'reset']}, 
        {"jobStatuses": ['HELD'], "commands": ['release']}
      ]
    },
    {
      'workflowStatus': 'paused', 
      'jobCommandDict': [
        {"jobStatuses": ['UNKNOWN', 'PENDING', 'RUNNABLE'], "commands": ['hold', 'status']}, 
        {"jobStatuses": ['SUBMITTED'], "commands": ['cancel', 'terminate', 'status']}, 
        {"jobStatuses": ['RUNNING'], "commands": ['terminate', 'status']}, 
      ]
    },
    {
      'workflowStatus': 'abandoned', 
      'jobCommandDict': [
        {'jobStatuses': ['SUBMITTED'], "commands": ['cancel', 'terminate']},
        {'jobStatuses': ['RUNNING'], "commands": ['terminate']}
      ]
    },
  ]
}

export const state = {
  availableActions: [],
  availableJobActions: [],
  selectedAction: "",
  selectedJobAction: "",
  selectedJobStepAction: "",
  selectedStepAction: "",
  failedJobActions: "",
  incompleteJobActions: "",
  runningJobActions: "", 
  submittedJobActions: "", 
  runnableJobActions: "",
  pendingJobActions : "",
  unknownJobActions : "",
  completedJobActions : "",
  heldJobActions : "",
  availableStepActions: ""
}

export const mutations = {
  //cannot do async in mutations.
  populateWorkflowActions(state, availableActions){
    state.availableActions = availableActions
  },
  updateWorklowAction(state, selectedAction){
    state.selectedAction = selectedAction
  },
  populateWorkflowJobActions(state, workflowJobActions){
    state.availableJobActions = workflowJobActions
  },
  updateWorkflowJobAction(state, selectedJobAction){
    state.selectedJobAction = selectedJobAction
  },
  populateWorkflowStepActions(state, workflowStepActions){
    state.availableStepActions = workflowStepActions
  },
  updateWorkflowStepAction(state, selectedStepAction){
    state.selectedStepAction = selectedStepAction
  },
  updateWorkflowJobStepAction(state, selectedJobStepAction){
    state.selectedJobStepAction = selectedJobStepAction
  },
  // list of available actions for the columns on the Workflow > Steps > column jobs page
  updateUnknownJobActions(state, availableActions){
    state.unknownJobActions = availableActions
  },
  updatePendingJobActions(state, availableActions){
    state.pendingJobActions = availableActions
  },
  updateRunnableJobActions(state, availableActions){
    state.runnableJobActions = availableActions
  },
  updateSubmittedJobActions(state, availableActions){
    state.submittedJobActions = availableActions
  },
  updateRunningJobActions(state, availableActions){
    state.runningJobActions = availableActions
  },
  updateCompletedJobActions(state, availableActions){
    state.completedJobActions = availableActions
  },
  updateHeldJobActions(state, availableActions){
    state.heldJobActions = availableActions
  },
  updateFailedJobActions(state, availableActions){
    state.failedJobActions = availableActions
  },
  updateIncompleteJobActions(state, availableActions){
    state.incompleteJobActions = availableActions
  }
}

export const actions = {
  ///
  /// WORKFLOW RELATED ACTIONS
  ///
  loadWorkflowActions({commit, dispatch}, workflowStatus){
    try {
      const availableActions = constants.workflowDictionary[workflowStatus]
      commit('populateWorkflowActions', availableActions)
    } catch (e) {
      dispatch('notify', {message:e.message, status:false})
    } 
  },
  selectWorkflowAction({commit}, input){
    commit('updateWorklowAction', input.target.value) 
  },  
  async updateWorkflowStatus({commit, state, dispatch}){
    commit('load')
    try{
      const workflow = JSON.parse(JSON.stringify(this.state.workflow.workflow))
      const data = { "project":  workflow.project, "workflow_id": workflow.workflow_id, "command": state.selectedAction, "queue": workflow.queue }

      if(state.selectedAction==="cancel"){
        let user = await Auth.currentAuthenticatedUser()      
        Object.assign(data, {"reason": `Workflow cancelled by ${user.username}`})
      }

      const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
      if(state.selectedAction === "delete"){
        router.back()
        dispatch('notify', {message:'Workflow has been deleted', status:true})
      }
      dispatch('notify', {message:`${state.selectedAction} command for ${workflow.name} sent`, status:true})
    }
    catch(e){
      dispatch('notify', {message:e.message, status:false})
    }
    commit('loaded')
  },
    ///
  /// JOBS RELATED ACTIONS
  ///
  clearWorkflowJobActions({commit}){
    commit('populateWorkflowJobActions', [])
  },
  loadWorkflowJobActions({commit, dispatch}, jobsToManage){
    try {
      // used by: Jobs.vue, WorkflowJobsSearch.vue

      // return the currently selected workflow and derive status
      let workflow = JSON.parse(JSON.stringify(store.getters.getWorkflowForStepUpdate()))
      let workflowStatus = workflow.status

      let statusesToInclude = []
      for(let i=0; i<jobsToManage.length; i++){
        if(!statusesToInclude.includes(jobsToManage[i].status)){
          statusesToInclude.push(jobsToManage[i].status)
        }
      }
      //todo: change this level
      let availableJobCommands = []

      let jobCommands = constants.superCommandDictionary
      .find(wf => wf.workflowStatus === workflowStatus)

      if(jobCommands!==undefined){
        // loop through the statuses we need to include
        for(let i=0; i<jobCommands.jobCommandDict.length; i++){
          // loop through the job command dictionary to check each jobStatus
          for(let j=0; j<jobCommands.jobCommandDict[i].jobStatuses.length; j++){
            if(!availableJobCommands.includes(jobCommands.jobCommandDict[i].jobStatuses[j]) && statusesToInclude.includes(jobCommands.jobCommandDict[i].jobStatuses[j])){
              jobCommands.jobCommandDict[i].commands.forEach(jc => {
                if(!availableJobCommands.includes(jc)){
                  availableJobCommands.push(jc)
                }
              })
            }
          }
        }
      }
      commit('populateWorkflowJobActions', availableJobCommands)
    } catch (e) {
      dispatch('notify', {message:e.message, status:false})
    } 
  },
  loadJobActionsForSingleJob({commit}, job){
    // there's already a job ID and a workflowStatus ID
    const jobStatus = job.p2020_status
    let workflow = JSON.parse(JSON.stringify(store.getters.getWorkflowForStepUpdate()))
    let workflowStatus = workflow.status

    let wfCommandDict = constants.superCommandDictionary
      .find(wf => wf.workflowStatus === workflowStatus)

    let commands = []

    if(wfCommandDict!==undefined){
      wfCommandDict.jobCommandDict.forEach(jobCommandItem => {
        if (jobCommandItem.jobStatuses.includes(jobStatus)){
          commands = jobCommandItem.commands
        }
      })
      commit('populateWorkflowJobActions', commands)
    }
  },
  loadStepStatusTableJobActions({commit}){
    let workflow = JSON.parse(JSON.stringify(store.getters.getWorkflowForStepUpdate()))
    let workflowStatus = workflow.status
    
    let wfCommandDict = constants.superCommandDictionary
      .find(wf => wf.workflowStatus === workflowStatus)

    if (wfCommandDict !== undefined){
      commit('updateUnknownJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('UNKNOWN')).map(jc => jc.commands)[0])
      commit('updateRunnableJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('RUNNABLE')).map(jc => jc.commands)[0])
      commit('updateSubmittedJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('SUBMITTED')).map(jc => jc.commands)[0])
      commit('updatePendingJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('PENDING')).map(jc => jc.commands)[0])
      commit('updateRunningJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('RUNNING')).map(jc => jc.commands)[0])
      commit('updateCompletedJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('COMPLETED')).map(jc => jc.commands)[0])
      commit('updateFailedJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('FAILED')).map(jc => jc.commands)[0])
      commit('updateIncompleteJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('INCOMPLETE')).map(jc => jc.commands)[0])
      commit('updateHeldJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('HELD')).map(jc => jc.commands)[0])
    }
    else{
      commit('updateUnknownJobActions', [])
      commit('updateRunnableJobActions', [])
      commit('updateSubmittedJobActions', [])
      commit('updatePendingJobActions', [])
      commit('updateRunningJobActions', [])
      commit('updateCompletedJobActions', [])
      commit('updateFailedJobActions', [])
      commit('updateIncompleteJobActions', [])
      commit('updateHeldJobActions', [])
    }
  },
  selectWorkflowJobAction({commit}, input){
    commit('updateWorkflowJobAction', input.target.value) 
  },
  selectWorkflowJobStepAction({commit}, input){
    commit('updateWorkflowJobStepAction', input.target.value)
  },
  clearWorkflowJobAction({commit}){
    commit('updateWorkflowJobAction', "")
  },
  async updateWorkflowJobStatus({commit,state, dispatch}, jobDetails){
    commit('load')
    try{
      let requestValid = true
      if(state.selectedJobAction==="change_queue"){
        // make sure no jobs are 'submitted'
        let jobs = JSON.parse(JSON.stringify(this.state.workflowjobs.workflowjobs))
        let jobsalreadySubmitted = jobs.some(j => j.status==="SUBMITTED")
        if(jobsalreadySubmitted){
          dispatch('notify', {message:"Queue cannot be changed: Job already has SUBMITTED status ", status:false})
          requestValid=false
        }
      }
      if(requestValid){
        let data = { "project":  jobDetails.project, "workflow_id": jobDetails.workflow_id, "command": state.selectedJobAction, "job_list": jobDetails.jobs }
        // i need to get the job details jobs definition id. 
        if(state.selectedJobAction==="cancel"){
          let user = await Auth.currentAuthenticatedUser()      
          Object.assign(data, {"reason": `Step Jobs cancelled by ${user.username}`})
        }
        else if(state.selectedJobAction==="force_run"){
          data.command="run"
          Object.assign(data, {"force": true})
          const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
          dispatch('notify', {message:`Force Run command sent`, status:true})
        }
        else if(jobDetails.filters!==undefined && jobDetails.filters.level!==undefined && jobDetails.filters.level === "step"){
          const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
          dispatch('notify', {message:`${state.selectedJobAction} command sent for all ${jobDetails.filters.status} jobs in ${jobDetails.filters.steps}`, status:true})
        }
        else{
          const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
          dispatch('notify', {message:`${state.selectedJobAction} command sent to ${data.job_list.length} jobs`, status:true})
        }
      }
    }
    catch(e){
      let data = JSON.parse(e.response.data)
      dispatch('notify', {message:data.message, status:false})
    }
    commit('loaded')
  },

  ///
  /// STEP RELATED ACTIONS
  ///
  clearWorkflowStepActions({commit}){
    commit('populateWorkflowStepActions', [])
  },
  loadWorkflowStepActions({commit, dispatch}, stepsToManage){
    try {      
      let workflow = JSON.parse(JSON.stringify(store.getters.getWorkflowForStepUpdate()))
      let workflowStatus = workflow.status

      let statusesToInclude = []
      for(let i=0; i<stepsToManage.length; i++){
        for(let j=0; j<stepsToManage[i].jobStepStatuses.length; j++){
          if(!statusesToInclude.includes(stepsToManage[i].jobStepStatuses[j])){
            statusesToInclude.push(stepsToManage[i].jobStepStatuses[j])
          }
        }
      }

      let availableStepCommands = []
      let jobCommands = constants.superCommandDictionary
      .find(wf => wf.workflowStatus === workflowStatus)

      if(jobCommands!==undefined){
        // loop through the statuses we need to include
        for(let i=0; i<jobCommands.jobCommandDict.length; i++){
          // loop through the job command dictionary to check each jobStatus
          for(let j=0; j<jobCommands.jobCommandDict[i].jobStatuses.length; j++){
            if(!availableStepCommands.includes(jobCommands.jobCommandDict[i].jobStatuses[j]) && statusesToInclude.includes(jobCommands.jobCommandDict[i].jobStatuses[j])){
              jobCommands.jobCommandDict[i].commands.forEach(jc => {
                if(!availableStepCommands.includes(jc)){
                  availableStepCommands.push(jc)
                }
              })
            }
          }
        }
        commit('populateWorkflowStepActions', availableStepCommands)
      }

    } catch (e) {
      dispatch('notify', {message:e.message, status:false})
    } 
  },
  async updateWorkflowJobStepStatus({commit, state, dispatch}, jobStepDetails){
    commit('load')
    try{
      let data = { "project":  jobStepDetails.project, "workflow_id": jobStepDetails.workflow_id, "command": state.selectedJobStepAction, "filters": jobStepDetails.filters }
      if(state.selectedJobStepAction==="cancel"){
        let user = await Auth.currentAuthenticatedUser()      
        Object.assign(data, {"reason": `Step(s) procedure cancelled by ${user.username}`})
      }
      else if(state.selectedJobStepAction==="force run"){
        data.command="run"
        Object.assign(data, {"force": true})
        const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
        dispatch('notify', {message:`Force Run command sent`, status:true})
      }
      else{
        const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
      }
      dispatch('notify', {message:
        `${state.selectedJobStepAction} command sent to all ${jobStepDetails.filters.steps} 
          ${jobStepDetails.filters.status!==undefined?jobStepDetails.filters.status:""} 
        jobs `, status:true})
    }
    catch(e){
      let data = JSON.parse(e.response.data)
      dispatch('notify', {message:data.message, status:false})
    }
    commit('loaded')
  },
  async updateWorkflowStepStatus({commit,state, dispatch}, stepDetails){
    commit('load')
    try{
      const data = { "project":  stepDetails.project, "workflow_id": stepDetails.workflow_id, "command": state.selectedStepAction, "filters": stepDetails.filters }
      if(state.selectedStepAction==="cancel"){
        let user = await Auth.currentAuthenticatedUser()      
        Object.assign(data, {"reason": `Step(s) procedure cancelled by ${user.username}`})
      }
      else if(state.selectedStepAction==="force_run"){
        data.command="run"
        Object.assign(data, {"force": true})
        const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
        dispatch('notify', {message:`Force Run command sent`, status:true})
      }
      else{
        const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
      }
      dispatch('notify', {message:`${state.selectedStepAction} command sent to all ${stepDetails.filters.steps} jobs `, status:true})
    }
    catch(e){
      let data = JSON.parse(e.response.data)
      dispatch('notify', {message:data.message, status:false})
    }
    commit('loaded')
  },
  selectWorkflowStepAction({commit}, input){
    commit('updateWorkflowStepAction', input.target.value) 
  },
  clearWorkflowStepAction({commit}){
    commit('updateWorkflowStepAction', "")
  },
}

export const getters = {
  getWorkflowActions: state => state.availableActions,
  getSelectedAction: state => state.selectedAction,
  getWorkflowJobActions: state => state.availableJobActions,
  getSelectedJobAction: state => state.selectedJobAction,
  getWorkflowStepActions: state => state.availableStepActions,
  getSelectedStepAction: state => state.selectedStepAction,
  getSelectedJobStepAction: state => state.selectedJobStepAction,
  getHeldJobActions: state => state.heldJobActions,
  getCompletedJobActions: state => state.completedJobActions,
  getSubmittedJobActions: state => state.submittedJobActions,
  getFailedJobActions: state => state.failedJobActions,
  getIncompleteJobActions: state => state.incompleteJobActions,
  getRunningJobActions: state => state.runningJobActions,
  getRunnableJobActions: state => state.runnableJobActions,
  getPendingJobActions: state => state.pendingJobActions,
  getUnknownJobActions: state => state.unknownJobActions
}